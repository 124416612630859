import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { NavController } from '@ionic/angular';
import { ConfigService } from 'src/services/config/config.service';
import { AppToastService } from '../app-toast/app-toast.service';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {

  constructor(
    public inAppBrowser: InAppBrowser,
    public config: ConfigService,
    public navCtrl: NavController,
    public appToastService: AppToastService,
  ) {

  }
  openSite(url, closeUrl = "thankyou") {
    // await Browser.open({
    //   url: this.config.yourSiteUrlString + "/order-web-view?token=" + this.appUserService.getCustomerToken(),
    //   windowName: '_self'
    // });
    // setTimeout(() => {

    //   Browser.close()
    // }, 5000);

    // Browser.addListener('browserPageLoaded', () => {
    //   console.log('browserPageLoaded event called');
    // });
    // Browser.addListener("browserFinished", () => { })
    let headerColor = "#ffffff"
    let textColor = "#000000"
    if (this.config.darkModeBool) {
      headerColor = "#1e1e1e"
      textColor = "#ffffff"
    }
    let options: InAppBrowserOptions = {
      toolbarposition: "top",
      location: 'yes',//Or 'no' 
      clearcache: 'yes',
      clearsessioncache: 'yes',
      hideurlbar: "yes",
      zoom: 'no',//Android only ,shows browser zoom controls 
      closebuttoncolor: textColor,
      toolbarcolor: headerColor,
      fullscreen: "no",
      toolbar: 'yes', //iOS only
    };
    //url = this.config.yourSiteUrlString + "/order-web-view?token=" + this.appUserService.getCustomerToken()
    const b = this.inAppBrowser.create(url, '_blank', options)
    let orderPlaced = false
    b.on('loadstart').subscribe(res => {

      if (res.url.indexOf(closeUrl) != -1) {
        console.log(res.url);
        //this.appToastService.toast("Order Placed");
        this.navCtrl.navigateRoot("thank-you")
        orderPlaced = true
        b.close();
      }
    });
    b.on('loadstop').subscribe(res => {
      console.log('loadstop');
    });

    b.on('exit').subscribe(res => {
      if (!orderPlaced)
        this.appToastService.toast("Order Cancelled");
    });
  }

}
